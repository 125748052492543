<template>
  <div
    v-if="!loading"
    style="
      height: 100vh;
      contain: content;
      background: var(--v-greyRaised-base) !important;
    "
  >
    <div
      style="
        height: 36px;
        width: 100%;
        display: flex;
        align-items: center;
        background-color: var(--v-secondary-base) !important;
        background: var(--v-secondary-base) !important;
      "
    >
      <span style="font-size: 16px" class="ml-2"><b> Contacts</b></span>
    </div>
    <div class="contents">
      <div class="mainContentWrap">
        <v-toolbar flat dense>
          <v-spacer></v-spacer>
          <v-chip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  id="add-relationship-button"
                  small
                  icon
                  v-on="on"
                  @click="addRelationship()"
                >
                  <v-icon small>add_circle</v-icon>
                </v-btn>
              </template>
              Add Relationship
            </v-tooltip>
            <!-- <v-btn
              small
              icon
              id="filter-relationship-button"
              @click="filterDialog = !filterDialog"
            >
              <v-icon small>filter_alt</v-icon>
            </v-btn> -->
            <v-text-field
              id="search-relationship"
              placeholder="Search"
              class="mb-1"
              hide-details
              rounded
              clearable
              dense
              style="width: 300px"
              v-model="search"
            ></v-text-field>
            <v-tooltip top v-if="filterActive">
          <template v-slot:activator="{ on }">
            <v-btn
              rounded
              right
              small
              icon
              color="redPop"
              @click="resetFilters"
              v-on="on"
            >
              <v-icon>filter_alt_off</v-icon>
            </v-btn>
          </template>
          <span style="font-size: 12px">Clear Filters</span>
        </v-tooltip>
          </v-chip>
        </v-toolbar>
        <ag-grid-vue
          v-if="!$vuetify.breakpoint.mobile"
          groupDisplayType="multipleColumns"
          :animateRows="false"
          :rowGroupPanelShow="'always'"
          :autoSizeStrategy="{
            type: 'fitGridWidth',
            defaultMinWidth: 100,
            columnLimits: [
              {colId: 'relatedOrganisation.name', minWidth: 220},
            ],
          }"
          style="width: 100%; height: 90vh; font-size: 11px"
          :columnDefs="headers"
          :initialState="initialState"
          :rowData="organisations.data"
          :defaultColDef="defaultColDef"
          :enableRangeSelection="true"
          :allowContextMenuWithControlKey="true"
          :sideBar="true"
          :postProcessPopup="postProcessPopup"
          :getContextMenuItems="getContextMenuItems"
          :statusBar="statusBar"
          :getRowId="getRowId"
          @grid-ready="onGridReady"
          @state-updated="onStateUpdated"
          @rowClicked="routeToContact"
        >
        </ag-grid-vue>
        <v-col cols="12" class="pt-0 px-0" v-else>
          <v-list style="margin-bottom: 36px">
            <v-list-item
              @click="routeToContact(item)"
              style="border-bottom: 0.5px solid #808080"
              class="pl-2"
              v-for="item in filteredOrganisations"
              :key="item.id"
            >
              <v-list-item-avatar class="mr-2">
                <v-avatar
                  size="32"
                  class="mr-1"
                  v-if="item.relatedOrganisation.countryRegion"
                >
                  <v-img
                    contain
                    :src="`https://cdn.loglive.io/flags/4x3/${item.relatedOrganisation.countryCode.toLowerCase()}.svg`"
                  >
                  </v-img>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title style="font-size: 12px"
                  >{{ item.relatedOrganisation.name }}
                </v-list-item-title>
                <v-list-item-subtitle style="font-size: 20px"
                  >{{ item.relatedOrganisation.logliveCode }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <v-chip
                    x-small
                    class="px-1 mr-1"
                    :color="item.isSupplier ? 'redPop' : 'tertiary'"
                    v-if="item.isSupplier || item.isCustomer"
                    >{{
                      item.isSupplier
                        ? "Supplier"
                        : item.isCustomer
                        ? "Customer"
                        : ""
                    }}
                  </v-chip>
                  <v-chip x-small class="mr-1" v-if="item.producer"
                    >Producer</v-chip
                  >
                  <v-chip x-small class="mr-1" v-if="item.consignee"
                    >Consignee</v-chip
                  >
                  <v-chip x-small class="mr-1" v-if="item.shipper"
                    >Shipper</v-chip
                  >
                  <v-chip x-small class="mr-1" v-if="item.buyer">Buyer</v-chip>
                  <v-chip x-small class="mr-1" v-if="item.notifyParty"
                    >Notify Party</v-chip
                  >
                  <v-chip x-small class="mr-1" v-if="item.coldstore"
                    >Coldstore</v-chip
                  >
                  <v-chip x-small class="mr-1" v-if="item.transporter"
                    >Transporter</v-chip
                  >
                  <v-chip x-small class="mr-1" v-if="item.forwarder"
                    >Freight Forwarder</v-chip
                  >
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <v-chip
                    outlined
                    v-for="team in item.relationshipTeams.filter((x) => x.team)"
                    :key="team.id"
                    class="ma-1"
                    x-small
                  >
                    <v-icon x-small left :color="team.team.colour"
                      >fiber_manual_record</v-icon
                    >
                    {{ team.team.name }}
                  </v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-chip x-small outlined style="border: none">
                  <v-icon
                    x-small
                    left
                    :color="getClientStatusColor(item.clientStatus)"
                    >fiber_manual_record</v-icon
                  >
                  <span v-if="item.clientStatus" style="font-size: 8px">{{
                    item.clientStatus
                  }}</span>
                  <span v-else>N/A</span>
                </v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-col>
      </div>
      <!-- Add Relationship -->
      <v-dialog
        v-model="relationshipDialog"
        persistent
        width="1200px"
        :fullscreen="$vuetify.breakpoint.xs"
        scrollable
      >
        <v-card>
          <v-card-text>
            <OrganisationLookup
              :item="item"
              @refresh="getRelations(), clear()"
              @close="(relationshipDialog = false), (item = {}), getRelations()"
            />
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Advanced Filter Dialog -->
      <v-dialog
        v-model="filterDialog"
        persistent
        max-width="600px"
        :fullscreen="$vuetify.breakpoint.mobile"
      >
        <v-card :loading="loading">
          <v-toolbar color="transparent" flat>
            <v-card-title>
              <span class="text-h5">Advanced filter</span>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-btn plain depressed elevation="0" @click="clearFilters">
              <v-icon>filter_alt_off</v-icon>
            </v-btn>
            <v-btn plain depressed elevation="0" @click="filterSearch()">
              <v-icon>filter_alt</v-icon>
            </v-btn>
            <v-btn plain depressed elevation="0" @click="filterDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-list dense>
              <v-autocomplete
                dense
                v-model="selectedFilterCountry"
                clearable
                :items="countries"
                item-text="name"
                item-value="name"
                return-object
                label="Country"
                outlined
                :loading="loadingCountries"
              >
                <template v-slot:selection="data">
                  <v-avatar size="32" class="mr-2" v-if="data.item">
                    <v-img
                      v-if="data.item.iso2"
                      contain
                      :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`"
                    >
                    </v-img>
                  </v-avatar>
                  <span v-if="data.item">
                    {{ data.item.name }}
                  </span>
                </template>
                <template v-slot:item="data">
                  <v-list-item-avatar>
                    <v-img
                      contain
                      v-if="data.item.iso2"
                      :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`"
                    >
                    </v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <v-select
                multiple
                chips
                outlined
                dense
                closable
                :items="orgTeams"
                label="Teams"
                item-value="id"
                clearable
                item-text="name"
                v-model="searchItem.teams"
                :disabled="params.noTeams"
              >
                <template #selection="{ item }">
                  <v-chip small :color="item.colour">{{ item.name }}</v-chip>
                </template>
              </v-select>
              <v-divider></v-divider>
              <v-subheader>Contact Type</v-subheader>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title> Customers </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-switch v-model="searchItem.isCustomer"></v-switch>
                </v-list-item-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title> Suppliers </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-switch v-model="searchItem.isSupplier"></v-switch>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
              <v-subheader>Status</v-subheader>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title> Active </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-switch v-model="searchItem.isActive"></v-switch>
                </v-list-item-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title> Inactive </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-switch v-model="searchItem.inActive"></v-switch>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <!-- <v-container>
             
              <v-row justify="center">
                <v-container fluid>
                  <v-chip class="ma-2" :outlined="searchItem.isActive ? false : true"
                    @click="searchItem.isActive = !searchItem.isActive" color="success">
                    <v-icon small left>done</v-icon> Active
                  </v-chip>
                  <v-chip class="ma-2" :outlined="searchItem.inActive ? false : true"
                    @click="searchItem.inActive = !searchItem.inActive" color="danger">
                    <v-icon small left>close</v-icon> Inactive
                  </v-chip>
                </v-container>
              </v-row>
            </v-container> -->
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="infoStart" max-width="31vw">
        <v-card>
          <v-btn
            @click="infoStartGuide"
            icon
            style="position: absolute; right: 0; margin: 0.6vh 1vh 0 0"
            ><v-icon color="primary" size="30">close</v-icon></v-btn
          >
          <v-card-title class="d-flex justify-center">
            FRIKO Welcome to Contacts!
          </v-card-title>
          <v-card-text>
            <span>
              <p>FRIKO</p>
              <p>
                FRIKO <br />• Map out routes for transporters <br />• Add
                GeoJSON data to locations used determine if a vehicle is within
                a certain area <br />• Calculate accurate waiting times at
                loading points <br />• Calculate distances for billing purposes
                <br />• Pin-point locations for the purpose of reporting and
                analysis <br />• Map out farms that aren't found on Google Maps
              </p>
              If you would like learn how to load POI's into the system, click
              the button below to start the tutorial.
            </span>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn @click="startGuide" color="edit" class="mb-3">Start!</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div class="bottomBar" v-if="$vuetify.breakpoint.mobile">
        <v-chip style="height: 100%">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                id="add-relationship-button"
                small
                icon
                v-on="on"
                @click="addRelationship()"
              >
                <v-icon small>add_circle</v-icon>
              </v-btn>
            </template>
            Add Relationship
          </v-tooltip>
          <v-btn
            small
            icon
            id="filter-relationship-button"
            @click="filterDialog = !filterDialog"
          >
            <v-icon small>filter_alt</v-icon>
          </v-btn>
          <v-text-field
            id="search-relationship"
            placeholder="Search"
            class="mb-1"
            hide-details
            rounded
            clearable
            dense
            style="width: 300px"
            v-model="params.search"
          ></v-text-field>
        </v-chip>
      </div>
    </div>
  </div>
</template>

<script>
// import steps from './Steps/orgRelationshipSteps'
// import CreateRelationship from '../components/Relationship/CreateRelationship.vue'
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import Boolean from "../components/GridComponents/Boolean.vue";
import Country from "../components/GridComponents/Country.vue";
import Driver from "driver.js";
import "driver.js/dist/driver.min.css";
import OrganisationName from "../components/GridComponents/Organisation.vue";
import OrganisationLookup from "../components/SystemComponents/OrganisationLookup.vue";
import TableHeader from "../components/GridComponents/TableHeader.vue";
export default {
  components: { AgGridVue, Boolean, Country, OrganisationName, OrganisationLookup, agColumnHeader: TableHeader },
  data: () => ({
    steps: null,
    driver: null,
    contactModal: false,
    contact: {},
    countries: [],
    countryKey: 0,
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
      enableRowGroup: true,
      enablePivot: true,
      flex: 1,
      minWidth: 100,
      cellClass: "d-flex align-center",
      cellStyle: { textAlign: "center", fontSize: "11px" },
    },
    headers: [
      {
        headerName: "Name",
        field: "relatedOrganisation.name",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.relatedOrganisation
            ? {
                organisation: p.data.relatedOrganisation,
                clientStatus: p.data.clientStatus ?? "Unknown",
              }
            : null;
        },
      },
      {
        headerName: "LogLive Code",
        field: "relatedOrganisation.logliveCode",
      },
      {
        headerName: "Alias",
        field: "relatedOrganisation.alias",
      },
      {
        headerName: "Account Ref.",
        field: "accountReference",
      },
      // {
      //   headerName: "Status",
      //   field: "clientStatus",
      // },

      {
        headerName: "Country",
        field: "relatedOrganisation.countryRegion.name",
        cellRenderer: "Country",
        cellRendererParams: (p) => {
          return p.data && p.data.relatedOrganisation
            ? {
                country: p.data.relatedOrganisation.countryRegion,
              }
            : null;
        },
      },
      {
        headerName: "Customer",
        field: "isCustomer",
        cellRenderer: "Boolean",
        cellStyle: { justifyContent: "center" },
      },
      {
        headerName: "Supplier",
        field: "isSupplier",
        cellRenderer: "Boolean",
        cellStyle: { justifyContent: "center" },
      },
      // {
      //   headerName: "Teams",
      //   field: "teams",
      // },
      // {
      //   headerName: "Network Functions",
      //   field: "networkFunctions",
      // },
    ],
    gridApi: null,
    loading: false,
    loadingUsers: false,
    isLoading: false,
    initialState: {},
    loadingTable: false,
    page: 1,
    params: {
      limit: 15,
      offset: 0,
      search: null,
      filter: { isActive: [true] },
      teamFilter: null,
      noTeams: false,
    },
    networkFunctions: [
      {
        name: "Producer",
        attribute: "producer",
        selected: true,
      },
      {
        name: "Coldstore",
        attribute: "coldstore",
        selected: true,
      },
      {
        name: "Consignee",
        attribute: "consignee",
        selected: true,
      },
      {
        name: "Shipper",
        attribute: "shipper",
        selected: true,
      },
      {
        name: "Forwarder",
        attribute: "forwarder",
        selected: true,
      },
      {
        name: "Buyer",
        attribute: "buyer",
        selected: true,
      },
      {
        name: "Notify Party",
        attribute: "notifyParty",
        selected: true,
      },
    ],
    relationshipDialog: false,
    item: {},
    tags: [],
    tagKey: 0,
    organisations: {
      count: 0,
      data: null,
    },
    settings: {},
    tab: "0",
    tagSearch: "",
    timeout: null,
    filterDialog: false,
    filterActive: false,
    filterSetting: {},
    selectedFilterCountry: null,
    loadingCountries: false,
    searchTimer: undefined,
    orgTeams: [],
    searchTimeout: null,
    search: null,
    searchItem: {
      isActive: true,
      inActive: false,
      isCustomer: true,
      isSupplier: true,
      teams: [],
      country: "",
    },
    statusBar: {
      statusPanels: [
        {
          statusPanel: "agTotalRowCountComponent",
          align: "left",
        },
        {
          statusPanel: "agFilteredRowCountComponent",
        },
        {
          statusPanel: "agSelectedRowCountComponent",
        },
        {
          statusPanel: "agAggregationComponent",
        },
      ],
    },
    updateBadge: 2000,
    infoStart: false,
  }),
  watch: {
    search(val) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(async () => {
        this.gridApi.updateGridOptions({ quickFilterText: val });
      }, 400);
    },
  },
  computed: {
    filteredOrganisations() {
      let result = this.organisations.data ?? [];
      let filter = {};
      if (this.searchItem.isActive && this.searchItem.inActive) {
        filter.isActive = [true, false];
      } else if (this.searchItem.isActive && !this.searchItem.inActive) {
        filter.isActive = [true];
      } else if (!this.searchItem.isActive && this.searchItem.inActive) {
        filter.isActive = [false];
      }
      filter.isSupplier = [this.searchItem.isSupplier];
      filter.isCustomer = [this.searchItem.isCustomer];
      if (this.params.noTeams) {
        filter.teamFilter = null;
      } else {
        filter.teamFilter = this.searchItem.teams;
      }
      filter = JSON.parse(JSON.stringify(filter));
      filter.teamFilter = JSON.parse(JSON.stringify(filter.teamFilter));

      if (filter.teamFilter && filter.teamFilter.length > 0) {
        result = result.filter(
          (x) =>
            x.relationshipTeams &&
            x.relationshipTeams.some((y) =>
              filter.teamFilter.includes(y.teamId)
            )
        );
      }
      if (filter.isActive && filter.isActive.length > 0) {
        result = result.filter((x) => filter.isActive.includes(x.isActive));
      }
      if (filter.isSupplier.length > 0 && filter.isCustomer.length > 0) {
        result = result.filter(
          (x) =>
            filter.isCustomer.includes(x.isCustomer) ||
            filter.isSupplier.includes(x.isSupplier)
        );
      } else if (filter.isSupplier.length > 0) {
        result = result.filter((x) => filter.isSupplier.includes(x.isSupplier));
      } else if (filter.isCustomer.length > 0) {
        result = result.filter((x) => filter.isCustomer.includes(x.isCustomer));
      }
      if (this.selectedFilterCountry) {
        result = result.filter(
          (x) =>
            x.relatedOrganisation.countryCode == this.selectedFilterCountry.iso2
        );
      }

      return result;
    },
  },
  async created() {
    if (
      localStorage.getItem(
        "contactTableState_" + this.$store.state.currentOrg.id
      )
    ) {
      let state = JSON.parse(
        localStorage.getItem(
          "contactTableState_" + this.$store.state.currentOrg.id
        )
      );
      if (typeof state === "object" && state !== null) {
        this.initialState = state;
      }
    }
    this.clearFilters();
    this.getRelations();
    this.getUserTeams();
    // this.getTags();
    this.getOrganisationNetworkFunctions();
    this.getCountries();
    this.getTeams();
    await this.getSteps()
  },
  async mounted() {
    this.driver = new Driver({
      animate: false,
    });
  },
  // computed: {
  //   computeFilterCount() {
  //     let count = 0
  //     return count
  //   }
  // },
  methods: {
    async getSteps() {
        let guide = await this.$API.getGuideSteps({
            guideId: 'views/OrganisationRelationship/Steps',
        });
        this.steps = guide.steps
    },

    addRelationship() {
      this.item = {
        modalType: "Add",
        relatedOrganisation: {
          organisationUsers: [],
        },
        organisationTags: [],
        producer: false,
        coldstore: false,
        consignee: false,
        buyer: false,
        forwarder: false,
        notifyParty: false,
        shipper: false,
        transporter: false,
        shippingLine: false,
        jointShipper: false,
        jointForwarder: false,
        jointBuyer: false,
        inverse: {
          producer: this.settings.producer,
          coldstore: this.settings.coldstore,
          consignee: this.settings.consignee,
          buyer: this.settings.buyer,
          forwarder: this.settings.forwarder,
          notifyParty: this.settings.notifyParty,
          shipper: this.settings.shipper,
          transporter: this.settings.transporter,
          shippingLine: this.settings.shippingLine,
        },
        isCustomer: false,
        isSupplier: false,
        relationshipTeams: [],
      };
      this.relationshipDialog = true;
    },

    async changeActiveStatus(item) {
      item.isActive = !item.isActive;
      await this.$API.updateRelation(item);
    },
    computeFilterCount() {
      let filter = {};
      // this.params.filter = {}
      if (this.searchItem.isActive && this.searchItem.inActive) {
        filter.isActive = [true, false];
      } else if (this.searchItem.isActive && !this.searchItem.inActive) {
        filter.isActive = [true];
      } else if (!this.searchItem.isActive && this.searchItem.inActive) {
        filter.isActive = [false];
      }
      filter.isSupplier = [this.searchItem.isSupplier];
      filter.isCustomer = [this.searchItem.isCustomer];
      if (this.params.noTeams) {
        filter.teamFilter = null;
      } else {
        filter.teamFilter = this.searchItem.teams;
      }
      filter = JSON.parse(JSON.stringify(filter));
      filter.teamFilter = JSON.parse(JSON.stringify(filter.teamFilter));
      let keys = Object.keys(filter);
      let count = 0;
      for (let i = 0; i < keys.length; i++) {
        if (filter[keys[i]] && filter[keys[i]][0] === true) {
          count++;
        } else if (
          keys[i] == "teamFilter" &&
          filter.teamFilter != null &&
          filter[keys[i]].length > 0
        ) {
          count++;
        }
      }
      return count;
    },
    async getTeams() {
      let teams = await this.$API.getTeams(this.orgId);
      this.orgTeams = teams;
    },
    async getUserTeams() {
      this.myTeams = await this.$API.getUserTeams();
      // this.searchItem.teams = this.myTeams.map(x => x.teamId)
      // this.filterSearch()
    },
    async getCountries() {
      this.loadingCountries = true;
      let result = await this.$API.searchCountries();
      for (let i = 0; i < result.data.length; i++) {
        this.countries.push({
          name: result.data[i].name,
          iso2: result.data[i].iso2,
        });
      }
      this.loadingCountries = false;
    },
    editOrganisation(org) {
      this.item = org;
      this.item.modalType = "Edit";
      this.item.relationshipTeamIds = this.item.relationshipTeams.map(
        (x) => x.teamId
      );
      this.relationshipDialog = true;
    },
    postProcessPopup(params) {
      if (params.type !== "columnMenu") {
        return;
      }
      const ePopup = params.ePopup;
      ePopup.style.top = "65px";
      ePopup.style.left =
        params.column.actualWidth / 2 + params.mouseEvent.left + "px";
    },
    getContextMenuItems(params) {
      let result = [];
      if (!params.node.group) {
        result = [
          {
            name: "View Contact",
            icon: '<span class="ag-icon ag-icon-eye" unselectable="on" role="presentation"></span>',
            action: () => {
              if (params.node && params.node.data && params.node.data.id) {
                this.routeToContact(params.node.data);
              } else {
                this.$message.error(
                  "Please select a single row to view the file"
                );
              }
            },
          },
          "separator",
          {
            name: "Add to Filter",
            icon: '<span class="ag-icon ag-icon-filter" unselectable="on" role="presentation"></span>',
            action: () => {
              if (params.node && params.node.data) {
                let filter = this.gridApi.getFilterModel();
                if (filter[params.column.colId]) {
                  filter[params.column.colId].values.push(params.value);
                } else {
                  filter[params.column.colId] = {
                    values: [params.value],
                  };
                }
                this.gridApi.setFilterModel(filter);
              } else {
                this.$message.error(
                  "Please select a single row to view the file"
                );
              }
            },
          },
          "separator",
          "copy",
          "copyWithHeaders",
          "export",
          "chartRange",
        ];
      } else {
        result = ["copy", "copyWithHeaders", "export", "chartRange"];
      }

      return result;
    },
    async getRelations() {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }
      this.loadingTable = true;
      this.searchTimer = setTimeout(async () => {
        this.organisations = await this.$API.getRelation({
          // params: this.params,
        });
        this.loadingTable = false;
      }, 500);
    },
    async getRelationsSearchMobile() {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }
      this.loadingTable = true;
      this.searchTimer = setTimeout(async () => {
        this.organisations = await this.$API.getRelation({
          params: this.params,
        });
        this.loadingTable = false;
      }, 500);
    },
    async getTags() {
      this.tags = await this.$API.getTagsByType("organisation");
    },
    async handleSelect(item) {
      if (this.item.id) {
        let result = await this.$API.createOrganisationTag({
          tagId: item.id,
          organisationId: this.item.id,
        });
        this.item.organisationTags.push(result);
      } else {
        this.item.organisationTags.push({
          tag: item,
          tagId: item.id,
          organisationId: this.item.id,
        });
      }
    },
    async getOrganisationNetworkFunctions() {
      this.settings = await this.$API.getOrganisationNetworkFunctions();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    onStateUpdated(state) {
      localStorage.setItem(
        "contactTableState_" + this.$store.state.currentOrg.id,
        JSON.stringify(state.state)
      );
    },
    removeOrganisation(org) {
      this.$confirm(
        "Are you sure you want to delete this Organisation relationship?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          await this.$API.updateRelation({
            id: org.id,
            isActive: false,
            isDeleted: true,
          });
          this.getRelations();
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    async saveRelationship(item) {
      if (!item.id) {
        item.relatedOrganisationId = item.relatedOrganisation.id;
        item.inviteAccepted = true;
        let relation = await this.$API.createRelation(item);
        if (item.relationshipTeams) {
          let teams = item.relationshipTeams.map((x) => ({
            teamId: x,
            organisationRelationshipId: relation.id,
          }));
          if (teams.length > 0) {
            await this.$API.createRelationshipTeam({
              batch: teams,
            });
          }
        }
        this.$message.success("Successfully Created!");
      } else {
        await this.$API.updateRelation(item);
        this.relationshipDialog = false;
        this.item = {};
        this.$message.success("Successfully Updated!");
      }
      this.getRelations();
      this.clear();
    },
    async saveOrganisation(item) {
      this.loading = true;
      if (item.id) {
        await this.updateOrganisation();
        this.$message.success("Update successful!");
      } else {
        let org = await this.$API.createOrganisation(item.relatedOrganisation);
        item.relatedOrganisationId = org.id;
        item.inviteAccepted = true;
        let relation = await this.$API.createRelation(item);
        if (item.relationshipTeams) {
          let teams = item.relationshipTeams.map((x) => ({
            teamId: x.id,
            organisationRelationshipId: relation.id,
          }));
          if (teams.length > 0) {
            await this.$API.createRelationshipTeam({
              batch: teams,
            });
          }
        }

        this.$message.success("Successfully created!");
        this.getRelations();
      }
      this.clear();
    },
    async updateOrganisation() {
      await this.$API.updateRelation(this.item);
      this.clear();
    },
    clear() {
      this.loading = false;
      this.relationshipDialog = false;
      this.item = {
        relatedOrganisation: {},
      };
    },
    async filterSearch() {
      this.params.filter = {};
      if (this.searchItem.isActive && this.searchItem.inActive) {
        this.params.filter.isActive = [true, false];
      } else if (this.searchItem.isActive && !this.searchItem.inActive) {
        this.params.filter.isActive = [true];
      } else if (!this.searchItem.isActive && this.searchItem.inActive) {
        this.params.filter.isActive = [false];
      }
      this.params.filter.isSupplier = [this.searchItem.isSupplier];
      this.params.filter.isCustomer = [this.searchItem.isCustomer];
      if (this.params.noTeams) {
        this.params.teamFilter = null;
      } else {
        this.params.teamFilter = this.searchItem.teams;
      }
      this.params.filter = JSON.parse(JSON.stringify(this.params.filter));
      this.params.teamFilter = JSON.parse(
        JSON.stringify(this.params.teamFilter)
      );
      this.filterActive = true;
      this.filterDialog = false;
      this.updateBadge++;
      // this.getRelations()
    },
    getClientStatusColor(status) {
      switch (status) {
        case "GOOD STANDING":
          return "green";
        case "INACTIVE":
          return "red";
        case "ON HOLD":
          return "orange";
        case "COD":
          return "blue";
      }
    },
    closeFilterDialog() {
      if (!this.filterActive) {
        this.clearFilterDialog();
      }
      this.filterDialog = false;
    },
    clearFilters() {
      this.clearFilterDialog();
      // this.getRelations()
    },
    async clearFilterDialog() {
      this.params.filter = { isActive: [true] };
      this.params.teamFilter = null;
      this.params.noTeams = false;
      this.filterDialog = false;
      this.filterActive = false;
      this.selectedFilterCountry = null;
      this.page = 1;
      this.searchItem = {
        country: null,
        isActive: true,
        inActive: false,
        isCustomer: true,
        isSupplier: true,
        teams: null,
      };
    },
    filterListener(event) {
      let filter = this.gridApi.getFilterModel();
      this.filterActive = Object.keys(filter).length > 0;
      this.filterSetting = filter;
    },
    getRowId(params) {
      return params.data.id;
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridApi.addEventListener("filterChanged", this.filterListener);
      this.getRelations();
    },
    async resetFilters() {
      this.gridApi.setFilterModel({});
    },
    resetTable() {
      this.gridApi.setFilterModel(null);
      this.gridApi.setRowGroupColumns([]);
      this.gridApi.applyColumnState({ state: this.columns });
      this.gridApi.refreshCells({ force: true });
    },
    routeToContact(contact) {
      let id = contact.data ? contact.data.id : contact.id;
      this.$router.push("/contact/" + id);
    },
    infoStartGuide() {
      if (this.infoStart) {
        this.infoStart = false;
      } else {
        this.infoStart = true;
      }
    },
    startGuide(e) {
      this.infoStart = false;
      e.stopPropagation();
      this.driver.defineSteps(this.steps)
      this.driver.start()
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-form {
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 0;
  // padding: 10px;
}
.bottomBar {
  height: 3rem;
  width: 100dvw;
  position: fixed;
  display: flex;
  padding: 0.2rem 0.5rem;
  bottom: 0;
  background-color: var(--v-greyRaised-base) !important;
  align-content: center;
}
.contents {
  display: flex;
  width: 100%;
  height: calc(100vh - var(--toolBarHeight));
  background: var(--v-greyRaised-base) !important;
}
.mainContentWrap {
  background: var(--v-greyRaised-base) !important;
  height: 100%;
  min-height: 100%;
  width: 100%;
  min-width: 50%;
  max-width: 100%;
  position: relative;
}
</style>
